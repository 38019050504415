import React from 'react';
import { StickyProvider } from 'contexts/app/app.provider';
import 'react-multi-carousel/lib/styles.css';
import 'react-modal-video/css/modal-video.min.css';
import 'rc-drawer/assets/index.css';

import Seo from '../components/seo';
import Layout from '../components/layout';
import Banner from '../sections/banner';
import Museum from '../sections/museum';
import Where from '../sections/where';
import Visit from '../sections/visit';
import About from '../sections/about';
import Partners from '../sections/partners';

export default function IndexPage() {
  return (
    <StickyProvider>
      <Layout>
        <Seo title="Museo Camposilvano" />
        <Banner lang="it"/>
        <Museum />
        <About/>
        <Visit />
        <Where />
        <Partners/>
      </Layout>
    </StickyProvider>
  );
}
